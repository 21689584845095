import React from "react";
import { Link } from "gatsby";
import sectionIcon from "../images/section-icon.png";
const DonationMakesDiiference = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="about-heading">
                            <div className="section-heading">
                                <br />
                                <div className="section-icon">
                                    <img src={sectionIcon} alt="section-icon" />
                                </div>
                                <h2 className="section__title">Your donation makes a diiference</h2>
                                {/* <p className="section__meta">why JCI</p> */}
                                <p className="section__desc">
                                    Your contributions to JCI India Foundation remain in an interest-bearing account and the interest generated on such donations is allotted for JCI India programs, including
                                </p>
                                <br />
                                <h2 className="section__title">Scholarship for economically deprived school children</h2>
                                <p className="section__desc">
                                    <ul className="form__list">
                                        <li>
                                            <i className="donate-content-list fa fa-check"></i>
                                            Every year thousands of applications of economically deprived school children are received from the local organizations of JCI India.
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Each year thousands of school children get the scholarship from JCI India Foundation
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Your contribution will enable us to donate for one more child every year.
                                        </li>
                                    </ul>
                                </p>
                                <br />
                                <h2 className="section__title">Training young leadership</h2>
                                <p className="section__desc">
                                    <ul className="form__list">
                                        <li>
                                            <i className="donate-content-list fa fa-check"></i>
                                            JCI India has over 1400+ local organization chapters (LOM) across India with the active member of over 100,000 across India.
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Every year these organizations are led by a new president of the LOM who are trained free of cost by JCI India called Presidential Academy.
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            These kinds of training programs that empower the young with leadership skills by JCI India are financially supported by JCI India Foundation
                                        </li>
                                    </ul>
                                </p>
                                <br />
                                <h2 className="section__title">Social welfare projects</h2>
                                <p className="section__desc">
                                    <ul className="form__list">
                                        <li>
                                            <i className="donate-content-list fa fa-check"></i>
                                            Every year JCI India Foundation, through JCI India contributes to the betterment of local community and society
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            We support instalment of water purifiers for providing safe drinking water in rural areas
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            Support areas hit by natural calamities during the hours of need
                                        </li>
                                        <li>
                                            <i className=" donate-content-list fa fa-check"></i>
                                            And numerous other social and community development projects
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="header-btn ml-auto">
                            <Link to="/donate" className="theme-btn">
                                donate now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DonationMakesDiiference;
