import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import DonationMakesDiiference from "../components/DonationMakesDiiference";
// import MixerAreaTwo from "../components/MixerAreaTwo";
// import TeamArea from "../components/TeamArea";
// import FaqArea from "../components/FaqArea";
// import ServiceArea from "../components/ServiceArea";
// import ClientsLogoTwo from "../components/ClientsLogoTwo";
import Footer from "../components/Footer";

const DonationMakesDiiferencePage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | Your donation makes a diiference">
            <NavOne />
            <PageHeader title="Your donation makes a diiference" />
            <DonationMakesDiiference />
            <Footer />
        </Layout>
    );
};

export default DonationMakesDiiferencePage;
